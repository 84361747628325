import { LoadingOutlined } from '@ant-design/icons';
import React, { ButtonHTMLAttributes, FC } from 'react';
import styled, { css } from 'styled-components';

import { getHue } from '../../style/utils';

type Props = ButtonHTMLAttributes<any> & {
  children: React.ReactElement;
  isLoading?: boolean;
  name?: string;
  variant?: string;
  fullWidth?: boolean;
  color?: string;
};

const WrapperLoading = styled.div`
  margin-left: 20px;
`;

const Button: FC<Props> = ({ className, children, isLoading, type = 'button', name, ...rest }) => (
  <button {...rest} className={className} data-testid={`button-${name}`} type={type}>
    {children}
    {isLoading && (
      <WrapperLoading>
        <LoadingOutlined />
      </WrapperLoading>
    )}
  </button>
);

const ButtonStyled = styled(Button)`
  ${({
    theme: { fontSize, colors, breakpoints },
    variant,
    fullWidth,
    isLoading,
    disabled,
    color = colors.baseColor,
  }) => {
    const hoverColor = `hsl(${getHue(color)}, 48%, 49%)`;

    let background = color;
    let fontColor = colors.white;
    let backgroundHover = hoverColor;
    let colorHover = colors.white;
    let border = 'none';
    let disabledCss;

    if (variant === 'secondary') {
      background = 'transparent';
      fontColor = color;
      backgroundHover = color;
      colorHover = colors.white;
      border = `1px solid ${color}`;
    }

    if (variant === 'backtest') {
      background = '#FF7F50';
      fontColor = colors.white;
      backgroundHover = '#FF7F50';
      colorHover = colors.white;
      border = '1px solid #FF7F50';
    }

    if (variant === 'product-m1') {
      background = 'transparent';
      fontColor = '#FF7F50';
      backgroundHover = '#FF7F50';
      colorHover = colors.white;
      border = '1px solid #FF7F50';
    }

    if (variant === 'product-m2') {
      background = 'transparent';
      fontColor = '#0080AF';
      backgroundHover = '#0080AF';
      colorHover = colors.white;
      border = '1px solid #0080AF';
    }

    if (variant === 'backtest-m2') {
      background = '#0080AF';
      fontColor = colors.white;
      backgroundHover = '#0080AF';
      colorHover = colors.white;
      border = '1px solid #0080AF';
    }

    if (variant === 'product-m3') {
      background = 'transparent';
      fontColor = '#50d0ff';
      backgroundHover = '#50d0ff';
      colorHover = colors.white;
      border = '1px solid #50d0ff';
    }

    if (variant === 'backtest-m3') {
      background = '#50d0ff';
      fontColor = colors.white;
      backgroundHover = '#50d0ff';
      colorHover = colors.white;
      border = '1px solid #50d0ff';
    }

    if (disabled) {
      disabledCss = css`
        border: none;
        background: ${colors.gray};
        color: ${colors.white};

        &:hover {
          cursor: default;
          transform: unset;
          background: ${colors.gray};
        }
      `;
    }

    return css`
      ${fullWidth && 'width: 100%'};
      ${isLoading && 'opacity: 0.6'};
      border-radius: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      position: relative;
      outline: none;
      background: ${background};
      color: ${fontColor};
      border: ${border};
      transition: 0.2s all;
      min-width: 36px;
      min-height: 36px;
      white-space: normal;
      padding: 10px 26px;

      @media screen and (min-width: ${breakpoints.lg}) {
        font-size: ${fontSize.small};
      }

      &:hover {
        cursor: pointer;
        transform: translate(2px, -2px);
        background: ${backgroundHover};
        color: ${colorHover};
      }

      &:active {
        cursor: pointer;
        transform: translate(2px, -2px);
        background: ${backgroundHover};
        color: ${colorHover};
      }

      &:focus {
        cursor: pointer;
        transform: none;
      }
      ${disabledCss}
    `;
  }}
`;

export default ButtonStyled;
